import { Link } from "react-router-dom"; 
import { Helmet } from "react-helmet";

export default function Custom404() {
  return (
    <div className="h-[800px]">
      <Helmet>
        <title>
          Page Under Construction | Applied BAS
        </title>
        <meta charset="UTF-8" />
        <meta name="description" content="Page accessed might not exists or be in construction. Please visit the home page." />
      </Helmet>
      <div className="mt-80 text-center">
        <h1 className="text-3xl font-semibold">Page under construction</h1>
        <p className="">
          This page will be ready soon. In the meanwhile, please head back to
          <Link to="/" className="text-primary hover:underline"> Home</Link>
        </p>
      </div>
    </div>
  );
}
