
export default function Header(props) {
  let perspective = 'object-bottom'
  if (props.top) {
    perspective = 'object-top';
  }
  else if(props.center) {
    perspective = 'object-center';
  }
  return (
    <section className={`${props.video ? 'h-screen' : 'h-[450px]'} flex justify-center pt-[1px] w-full relative`}>
      {props.video ? <video className="w-full object-cover" autoPlay muted loop playsInline>
        <source src={props.video} type="video/mp4" />
        <source src={props.videoAlt} type="video/webm" />
      </video> : <img src={props.image} alt="Background header" className={`w-full object-cover ${perspective}`} />}
      <div className="flex flex-wrap justify-center align-middle w-full bg-gradient-to-b from-[#00000090] to-[#20202040] absolute top-0 left-0 h-full px-4 lg:px-8">
        <div className="w-full max-w-[1250px] mt-4 lg:mt-0">{props.content}</div>
      </div>
    </section>
  );
}
