import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Layout from "./components/layout";
import TermsAndConditions from "./pages/terms-and-conditions";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./components/scrollToTop";
import Custom404 from "./pages/404";
import Contact from "./pages/contact";
import AboutUs from "./pages/about-us";
import Projects from "./pages/projects";
import ChrisLewisAbout from "./pages/about-us/chris-lewis";
import ChrisGonzalesAbout from "./pages/about-us/chris-gonzales";
import JessicaLewisAbout from "./pages/about-us/jessica-lewis";
import Careers from "./pages/careers";
import PlanningAndDesign from "./pages/services/planning-and-design";
import IntegrationAndOptimization from "./pages/services/integration-and-optimization";
import BuildingAutomationImplementation from "./pages/services/building-automation-implementation";
import BuildingAutomationSupport from "./pages/services/building-automation-support";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
      <Layout>
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/about-us/chris-lewis' element={<ChrisLewisAbout />} />
          <Route path='/about-us/chris-gonzales' element={<ChrisGonzalesAbout />} />
          <Route path='/about-us/jessica-lewis' element={<JessicaLewisAbout />} />
          <Route path='/services/planning-and-design' element={<PlanningAndDesign />} />
          <Route path='/services/building-automation-implementation' element={<BuildingAutomationImplementation />} />
          <Route path='/services/integration-and-optimization' element={<IntegrationAndOptimization />} />
          <Route path='/services/building-automation-support' element={<BuildingAutomationSupport />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='*' element={<Custom404 />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
