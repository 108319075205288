import LogoBright from "../assets/images/ABAS_logo_bright.png";
import { AiOutlinePhone, AiOutlineLink, AiOutlineMail } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";

export default function Footer() {
  const underlineStyle = "hover:underline hover:underline-offset-4 hover:cursor-pointer";

  return (
    <footer className="w-full bg-[#002028] py-4 px-4 lg:px-8 z-0">
      <div className="pt-10 grid m-auto md:grid-cols-4 max-w-[1250px]">
        <div className="col-span-2 md:col-span-4">
          <div className="">
            <Link to={"/"}>
              <img
                src={LogoBright}
                height={70}
                width={107.4}
                alt="Applied BAS LLC Logo Bright"
                className="!important"
              />
            </Link>
          </div>
          <div className="mt-4 mb-8">
            <ul className="list-none ">
              <li className="mt-2">
                <p className="text-white">
                  Tomorrow’s Technologies in your Building Today
                </p>
              </li>
              <li className="flex-wrap mt-1">
                <p className="text-subtitle">
                  Serving Dallas, FT. Worth, and beyond
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-span-2 md:col-span-4">
          <div className="mx-2 w-50 h-[2px] bg-[#383838]"></div>
        </div>
        <div className="pr-4 my-4">
          <ul className="text-white">
            <li className="font-semibold text-subtitle">
              Services
            </li>
            <li className="my-4">
              <Link to="services/planning-and-design" className={underlineStyle}>
                Planning & Design
              </Link>
            </li>
            <li className="my-4">
              <Link to="services/building-automation-implementation" className={underlineStyle}>
              Building Automation Implementation
              </Link>
            </li>
            <li className="my-4">
              <Link to="services/integration-and-optimization" className={underlineStyle}>
                Integration & Optimization
              </Link>
            </li>
            <li className="my-4">
              <Link to="services/building-automation-support" className={underlineStyle}>
              Building Automation Support
              </Link>
            </li>
          </ul>
        </div>
        <div className="pr-4 my-4">
          <ul className="text-white">
            <li className="font-semibold text-subtitle">
              Company
            </li>
            <li className="my-4">
              <Link to="about-us" className={underlineStyle}>
                About Us
              </Link>
            </li>
            <li className="my-4">
              <Link to="projects" className={underlineStyle}>
                Our Projects
              </Link>
            </li>
          </ul>
        </div>
        <div className="pr-4 my-4">
          <ul className="text-white">
            <li className="">
              <Link to="careers" className={underlineStyle}>
                Careers
              </Link>
            </li>
          </ul>
        </div>
        <div className="my-4">
          <ul className="text-white">
            <li className="font-semibold text-subtitle">
              <p className="font-semibold text-subtitle">Contact Info</p>
            </li>
            <li className="my-4">
              <a className={underlineStyle} href="tel:123-456-7890">
                <AiOutlinePhone className="inline-block mr-2" />
                +1 (469) 676-8749
              </a>
            </li>
            <li className="my-4">
              <a
                className={underlineStyle}
                href="mailto:info@appliedbas.com?subject=Client%20Contact"
              >
                <AiOutlineMail className="inline-block mr-2" />
                info@appliedbas.com
              </a>
            </li>
            <li className="my-4">
              <Link
                className={underlineStyle}
                to="/"
              >
                <AiOutlineLink className="inline-block mr-2" />
                appliedbas.com
              </Link>
            </li>
            <li className="my-4">
              <a className={underlineStyle} href="a">
                <ImLocation className="inline-block mr-2" />
                Dallas, TX, USA
              </a>
            </li>
          </ul>
        </div>
        <div className="text-sm col-span-2 md:col-span-4 text-center mt-20 py-6">
          <ul className="text-subtitle">
            <li className="inline-block mx-4 my-2">
              <p>@Applied BAS LLC. All rights reserved</p>
            </li>
            <li className="inline-block mx-4 my-2">
              <Link to="terms-and-conditions" className={underlineStyle}>
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
