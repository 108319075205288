import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import BGImage from '../../assets/images/pexels-fauxels-3184405.jpg';
import Header from "../../components/header";
import ProgramerImg from "../../assets/images/Rectangle 399.png";
import TechnicalImg from "../../assets/images/Rectangle 398.png";
import SalesImg from "../../assets/images/Rectangle 400.png";
import CultureImg from "../../assets/images/Rectangle 401.png";

export default function Careers() {
  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl max-w-2xl font-bold text-white ">
        Your Moment To Power a Better Future.
      </h1>
    </div>
  );

  const Category = (props) => {
    return (
      <div className="">
        <img src={props.image} alt={props.alt} className="m-auto rounded-lg" />
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
          <h3 className="text-xl font-bold text-center mt-4">{props.title}</h3>
          <p className="text-center">{props.content}</p>
        </AnimationOnScroll>
      </div>
    );
  }

  const JobApplication = (props) => {
    return (
      <div className="p-4 grid grid-cols-1 lg:grid-cols-3 bg-white mt-4">
        <h4 className="text-xl font-bold">{props.title}</h4>
        <p className="">{props.description}</p>
        <div className="m-auto">
          <Link to="#" className="text-primary">Apply</Link>
        </div>
      </div>
    );
  }


  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Careers | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
      </Helmet>
      <Header content={headerContent} image={BGImage} center />
      <section className="pt-12 pb-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <div className="w-[268px] m-auto text-primary">
            <p className="inline-block">#lifestyle</p>
            <p className="inline-block ml-4">#inspiration</p>
            <p className="inline-block ml-4">#technology</p>
          </div>
          <h1 className="text-3xl font-bold text-center mt-4">How We Work</h1>
          <p className="max-w-xl m-auto text-center mt-2">At Applied BAS we divide into three major work sectors. And within each sector, we value every employee and make sure they obtain the proper help and training to succeed in their career. </p>
          <div className="grid grid-cols-1 gap-y-16 gap-4 lg:grid-cols-3 mt-16">
            <Category image={ProgramerImg} alt="Programmer" title="Programming" content="Better engineer the products that our employees and clients use on a daily basis to improve the future." />
            <Category image={TechnicalImg} alt="Technician" title="Technical" content="Find the faults in the buildings systems and problem solve the best way to fix their issues." />
            <Category image={SalesImg} alt="Sales Person" title="Sales" content="Go above and beyond selling our services to potential companies in need of a better building automation system." />
          </div>
        </div>
      </section>
      <section className="mt-20 py-8 px-4 lg:px-8 w-full lg:h-[500px]">
        <div className="w-full max-w-[1250px] m-auto relative">
          <div className="w-full lg:w-1/2 lg:absolute lg:right-0 lg:pl-8 text-primary">
            <p className="inline-block mr-4 ">#meninbusiness</p>
            <p className="inline-block">#businesswoman</p>
          </div>
          <div className="w-full lg:w-1/2 lg:pr-8 lg:absolute lg:left-0 lg:top-0">
            <img src={CultureImg} alt="" className="w-full rounded-lg m-auto object-cover" />
          </div>
          <div className="w-full lg:w-1/2 lg:absolute lg:right-0 lg:top-8 lg:pl-8">
            <h2 className="text-3xl font-bold mb-2 text-center lg:text-left">
              Culture
            </h2>
            <div className="my-4">
              <p>
                We believe that every employee must feel that they belong as it improves their quality life and our business model.
              </p>
            </div>
            <div className="my-4">
              <p>
                Wether you are looking to be a technician, engineer, or a sales person, we have something for you with us. Looking to be a technician, engineer, or a sales person, we have something for you with us.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-24 py-8 px-4 lg:px-8 mb-24 bg-[#F0F0F0]">
        <div className="max-w-[1250px] m-auto">
          <div className="grid grid-cols-1">
            <div className="m-auto">
              <p className="inline-block text-primary">#problemsolving</p>
              <p className="inline-block text-primary ml-4">#futurechallenge</p>
            </div>
            <h3 className="text-3xl font-bold mt-4 text-center">
              Solve Real Challenges
            </h3>
            <p className="mt-2 text-center max-w-2xl m-auto">It cant get any more real than provindg real solutions to improve our company model and improve other buildings&apos; productivity, efficiency, and security.</p>
            <div className="text-center">
            <Link to="#" className="btn mt-10">Coming Soon</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
