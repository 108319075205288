import { Helmet } from "react-helmet";
import BGImage from "../../../assets/images/pexels-expect-best-323705.jpg";
import Header from "../../../components/header";

export default function IntegrationAndOptimization() {
  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl font-bold text-white">
        Integration and Optimization
      </h1>
      <p className="drop-shadow-lg text-white text-xl sm:text-2xl font-semibold mt-4 w-full">
        Another step of our services that demonstrates our versatility in Automation
      </p>
    </div>
  );
  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Integration & Optimization - Services | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area."
        />
      </Helmet>
      <Header content={headerContent} image={BGImage} />
      <section className="py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <p className="text-primarydark">Services</p>
          <h1 className="text-4xl font-bold">
            Multi-vendor/legacy support
          </h1>
          <p className="mt-4">
            Regardless of the products that our clients prefer or our favorite
            vendors, we have an assortment of available supplies. Even if the
            building systems are no longer maintained, we will help support your
            current systems or handle many different vendors to equip the
            building with the necessary technologies.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">
            Web-based & IoT Solutions
          </h1>
          <p className="mt-4">
            Our web-based solutions allow clients to view and access their
            building automation systems remotely. Therefore, our clients can
            change their BAS settings through the cloud and perform quick
            operations to adjust for an emergency. In addition, we help
            integrate different types of unique IoT sensors and controllers to
            adapt to the individual building design of our clients. These
            devices have various wireless and open protocol connections to
            communicate according to the building's architecture.
          </p>
        </div>
      </section>
    </div>
  );
}
