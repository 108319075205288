import { useEffect, useState } from "react";
import logo from "../assets/images/ABAS_logo.png";
import logoLight from "../assets/images/ABAS_logo_for_dark_backgrounds.png";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function NavBar() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [isServiceMenuActive, setServiceMenuActive] = useState(false);
  const [isCompanyMenuActive, setCompanyMenuActive] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);
  const [isMobileServiceActive, setIsMobileServiceActive] = useState(false);
  const [isMobileCompanyActive, setIsMobileCompanyActive] = useState(false);

  const servicesMenuItems = [
    { id: 0, name: "Planning & Design", to: "/services/planning-and-design" },
    {
      id: 1,
      name: "Building Automation Implementation",
      to: "/services/building-automation-implementation",
    },
    {
      id: 2,
      name: "Integration & Optimization",
      to: "/services/integration-and-optimization",
    },
    {
      id: 3,
      name: "Building Automation Support",
      to: "/services/building-automation-support",
    },
  ];

  const companyMenuItems = [
    { id: 4, name: "About Us", to: "/about-us" },
    { id: 5, name: "Our Projects", to: "/projects" },
  ];

  const dropdownStyle =
    "border-l-4 border-white rounded lg:hover:border-primary duration-200 bg-white text-black block px-4 py-2 text-sm lg:hover:text-primary z-40";

  const resetMenu = () => {
    setMenuActive(false);
    setIsMobileCompanyActive(false);
    setIsMobileServiceActive(false);
  };

  const ServicesMenuList = () => {
    return servicesMenuItems.map((item) => (
      <Link
        key={item.id}
        to={item.to}
        className={dropdownStyle}
        role="menuitem"
        tabIndex="-1"
        id={`menu-item-${item.id}`}
        onClick={() => resetMenu()}
      >
        {item.name}
      </Link>
    ));
  };

  const CompanyMenuList = () => {
    return companyMenuItems.map((item) => (
      <Link
        to={item.to}
        key={item.id}
        className={dropdownStyle}
        role="menuitem"
        tabIndex="-1"
        id={`menu-item-${item.id}`}
        onClick={() => resetMenu()}
      >
        {item.name}
      </Link>
    ));
  };

  const BurgerMenuIcon = () => {
    return (
      <div
        className="block lg:hidden z-50"
        onClick={() => setMenuActive(!isMenuActive)}
      >
        <button className="flex items-center px-3 py-2">
          <div className="flex flex-col items-end">
            <div
              className={`${
                isTransparent && !isMenuActive ? "bg-white" : "bg-secondary"
              } ${
                isMenuActive
                  ? "-rotate-45 w-7 translate-x-[5px] translate-y-[7px]"
                  : "w-5"
              } transition-transform transform ease-in-out duration-300 block rounded h-[3px] mb-1`}
            ></div>
            <div
              className={`${
                isTransparent && !isMenuActive ? "bg-white" : "bg-secondary"
              } ${
                isMenuActive ? "rotate-45 translate-x-[4px]" : "rotate-0 translate-x-0"
              } transition-transform transform duration-300 block rounded w-7 h-[3px] mb-1`}
            ></div>
            <div
              className={`${isTransparent ? "bg-white" : "bg-secondary"} ${
                isMenuActive ? "bg-opacity-0" : "bg-opacity-100"
              } block rounded w-4 h-[3px]`}
            ></div>
          </div>
        </button>
      </div>
    );
  };

  useEffect(() => {
    const lightPages = [
      "/about-us/chris-lewis",
      "/about-us/chris-gonzales",
      "/about-us/jessica-lewis",
      "/terms-and-conditions",
    ];

    const handleNavigation = () => {
      if (
        window.scrollY <= 10 &&
        !lightPages.includes(window.location.pathname)
      ) {
        setIsTransparent(true);
      } else {
        setIsTransparent(false);
      }
    };

    window.addEventListener("scroll", handleNavigation);
    window.addEventListener("click", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
      window.removeEventListener("click", handleNavigation);
    };
  }, []);

  return (
    <nav
      className={`${
        isTransparent ? "bg-transparent" : "bg-white shadow"
      } transition-all duration-200 fixed top-0 left-0 items-center w-full px-4 lg:px-8 z-50`}
    >
      <div className="flex items-center object-center justify-between flex-wrap w-auto m-auto max-w-[1250px]">
        <div
          className="flex items-center flex-shrink-0 mr-6"
          onClick={() => setMenuActive(false)}
        >
          <Link to="/" className="block w-20 z-50">
            <img
              src={isTransparent && !isMenuActive ? logoLight : logo}
              className="py-4"
              alt="Applied BAS LLC Logo"
            />
          </Link>
        </div>
        <BurgerMenuIcon />
        <div
          className={`hidden lg:flex transition-transform translate-y-0 z-10 w-full text-center lg:text-left lg:items-center lg:w-auto bg-transparent`}
        >
          <div
            className={`${
              isTransparent ? "text-white" : "text-black"
            } lg:flex-grow font-semibold pb-4 md:pb-0`}
          >
            <div
              className="relative lg:inline-block z-50"
              onMouseEnter={() => setServiceMenuActive(true)}
              onMouseLeave={() => setServiceMenuActive(false)}
            >
              <div
                aria-expanded="true"
                aria-haspopup="true"
                className="block transition rounded px-2 py-4 border-b-4 lg:hover:border-primary border-[#00000000] ease-out duration-500 mt-4 lg:inline-block lg:mt-0 lg:hover:text-primary lg:hover:cursor-default lg:mr-8 mb-0"
                id="services-menu-button"
                onTouchStart={() => setServiceMenuActive(true)}
              >
                Services
                <BiChevronDown className="hidden lg:inline-block ml-2 text-xl" />
              </div>
              <div
                id="services-menu"
                className={`${
                  isServiceMenuActive ? "block" : "hidden"
                } transition duration-500 absolute top-[52px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="services-menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  <ServicesMenuList />
                </div>
              </div>
            </div>
            <div
              className="relative lg:inline-block h-50 z-50"
              onMouseEnter={() => setCompanyMenuActive(true)}
              onMouseLeave={() => setCompanyMenuActive(false)}
            >
              <div
                aria-expanded="true"
                aria-haspopup="true"
                className="block transition rounded px-2 py-4 border-b-4 lg:hover:border-primary border-[#00000000] ease-out duration-500 mt-4 lg:inline-block lg:mt-0 lg:hover:text-primary lg:hover:cursor-default lg:mr-8 mb-0"
                id="company-menu-button"
                onTouchStart={() => setCompanyMenuActive(true)}
              >
                Company
                <BiChevronDown className="hidden lg:inline-block ml-2 text-xl" />
              </div>
              <div
                id="company-menu"
                className={`${
                  isCompanyMenuActive ? "block" : "hidden"
                } transition duration-500 absolute top-[52px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="company-menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  <CompanyMenuList />
                </div>
              </div>
            </div>
            <Link
              to="/careers"
              className="block transition rounded px-2 py-4 border-b-4 lg:hover:border-primary border-[#00000000] ease-out duration-500 mt-4 lg:inline-block lg:mt-0 lg:hover:text-primary lg:mr-10"
              onClick={() => setMenuActive(false)}
            >
              Careers
            </Link>
          </div>
        </div>
        <div className="hidden mr-4 lg:inline-block">
          <Link to="/contact" className="btn mt-4 lg:inline-block lg:mt-0">
            Contact Us
          </Link>
          <a
            href="https://cloud.appliedbas.com"
            className="btn bg-secondary mt-4 lg:inline-block lg:mt-0 lg:ml-4"
          >
            Client Login
          </a>
        </div>
        <div
          className={`${
            isMenuActive ? "fixed top-0 left-0" : "hidden"
          } bg-white block lg:hidden w-full z-40 h-screen`}
        >
          <div className="mt-20 border-t-2 border-t-[#DDD]">
            <div
              className="block transition rounded px-2 py-4 border-b-[2px] ease-out duration-500 mt-4 border-b-[#EEE]"
              onClick={() => {
                setIsMobileServiceActive(!isMobileServiceActive);
              }}
            >
              Services
              <BiChevronDown className="inline-block ml-2 text-xl" />
            </div>
            <div className={`${isMobileServiceActive ? "block" : "hidden"}`}>
              <ServicesMenuList />
            </div>
            <div
              className="block transition rounded px-2 py-4 border-b-[2px] ease-out duration-500 mt-4 border-b-[#EEE]"
              onClick={() => {
                setIsMobileCompanyActive(!isMobileCompanyActive);
              }}
            >
              Company
              <BiChevronDown className="inline-block ml-2 text-xl" />
            </div>
            <div className={`${isMobileCompanyActive ? "block" : "hidden"}`}>
              <CompanyMenuList />
            </div>
            <Link
              to="/careers"
              className="block transition rounded px-2 py-4 border-b-[2px] ease-out duration-500 mt-4 border-b-[#EEE]"
              onClick={() => resetMenu()}
            >
              Careers
            </Link>
            <Link
              to="/contact"
              className="block transition rounded px-2 py-4 border-b-[2px] ease-out duration-500 mt-4 border-b-[#EEE]"
              onClick={() => setMenuActive(false)}
            >
              Contact Us
            </Link>
            <a
              href="https://cloud.appliedbas.com"
              className="hidden lg:block transition rounded px-2 py-4 border-b-[2px] ease-out duration-500 mt-4 border-b-[#EEE]"
              onClick={() => resetMenu()}
            >
              Client Login
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
