import Footer from "./footer";
import NavBar from "./navbar";
import TopBanner from "./topbanner";

export default function Layout({ children }) {
  return (
    // TODO: detect scroll for showing nav and hidding it.
    <>
        <NavBar />
        <main>{children}</main>
        <Footer />
    </>
  );
}
