import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CGBackground from "../../assets/images/Chris-Gonz_window-bkgrnd.jpg";
import CLBackground from "../../assets/images/Chris-Lewis_window-bkgrnd.jpg";
import JLBackground from "../../assets/images/Jess-Lewis_window-bkgrnd.jpg";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Header from "../../components/header";
import BGImage from '../../assets/images/pexels-photomix-company-518244.jpg';

export default function AboutUs() {
  const Founder = (props) => (
    <div className="flex flex-col mt-4">
      <div className="flex flex-col">
        <Link className="m-auto mb-4 bg-white opacity-1 hover:opacity-80 transition-opacity duration-200" to={props.link}>
          <img
            src={props.img}
            alt={props.alt}
            width={300}
            className="w-[500px] h-[400px] object-cover rounded-lg"
          />
        </Link>
        <p className="text-xl">{props.title}</p>
        <p className="font-semibold">{props.description}</p>
      </div>
    </div>
  );

  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl font-bold text-white ">
        About us
      </h1>
      <p className="drop-shadow-lg text-white text-xl sm:text-2xl font-semibold mt-4 w-full">
        Learn a little bit more about who we are, our mission, our business model, and our administration.
      </p>
    </div>
  );


  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>About Us | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Check out our company to uncover how we deliver the best Building Automation technologies within the Dallas Fort-Worth area." />
      </Helmet>
      <Header content={headerContent} image={BGImage} center />
      <section className="py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <h1 className="text-3xl font-bold">Welcome To Applied BAS</h1>
          <p className="mt-4">
            Applied BAS, LLC delivers Building Automation Services providing the
            latest technologies in HVAC, DDC Controls, Lighting, and more, to meet our customers&apos; demands and growing
            industry. Found and led by a team dedicated to exceeding customer
            expectations, Applied BAS was established in 2016 and has combined
            industry knowledge of over 40 years. Our level of expertise coupled
            with wide technical resources allow the flexibility to meet our
            customer&apos;s long term and short-term infrastructure planning goals.
          </p>
        </div>
      </section>
      <section className="mt-10 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <h1 className="text-3xl font-bold">Our Administration</h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
            <Founder
              img={CGBackground}
              alt="Chris Gonzales Owner/Operator of Applied BAS"
              link="/about-us/chris-gonzales"
              title="Chris Gonzales"
              description="Owner/Operator"
            />
            <Founder
              img={CLBackground}
              alt="Chris Lewis Owner/Operator of Applied BAS"
              link="/about-us/chris-lewis"
              title="Chris Lewis"
              description="Owner/Operator"
            />
            <Founder
              img={JLBackground}
              alt="Jessica Lewis Administrative Manager of Applied BAS"
              link="/about-us/jessica-lewis"
              title="Jessica Lewis"
              description="Administrative Manager"
            />
          </div>
        </div>
      </section>
      <section className="mt-10 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <h1 className="text-3xl font-bold">Our Capabilities</h1>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full my-4">
              <p>Job Walk / Estimating</p>
              <p>System Integration</p>
              <p>Building Automation Technical Support</p>
              <p>Building Automation Design</p>
              <p>System Startup and Commissioning</p>
              <p>Building Automation Service and Maintenance</p>
              <p>Building Automation Installation</p>
              <p>Sequence/Program Planning and Implementation</p>
              <p>Occupant Comfort Conditioning</p>
              <p>Custom Graphical Interfaces</p>
              <p>Fault Detection and Automation Analytics</p>
            </div>
          </AnimationOnScroll>
        </div>
      </section>
    </div>
  );
}
