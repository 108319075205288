import { Helmet } from "react-helmet";
import BGImage from "../../../assets/images/pexels-scott-webb-139207.jpg";
import Header from "../../../components/header";

export default function BuildingAutomationImplementation() {
  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl font-bold text-white">
        Building Automation Implementation
      </h1>
      <p className="drop-shadow-lg text-white text-xl lg:text-2xl font-semibold mt-4 w-full">
        Where we take action to install, program, inspect buildings and automation.
      </p>
    </div>
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>
          Building Automation Implementation - Services | Applied BAS
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Check out our company to uncover how we strive to deliver building automation services with the latest technologies, within the Dallas Fort-Worth area."
        />
      </Helmet>
      <Header content={headerContent} image={BGImage} />
      <section className="py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <p className="text-primarydark">Services</p>
          <h1 className="text-4xl font-bold">
            Building Automation Installation
          </h1>
          <p className="mt-4">
            A team will inspect the current systems within the building for the
            new or upgraded equipment to be installed. This step consists of
            installing devices capable of receiving input, a computing system,
            and the manipulators that perform the work.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">Controller Programming</h1>
          <p className="mt-4">
            Controller systems are basic units that manage more extensive
            systems. These controllers look at the data from inputs (i.e.,
            temperature sensors) and provide outputs (i.e., actuators) as we
            connect them using specialized programming tools.
          </p>
        </div>
        <div className="w-full max-w-[1250px] m-auto mt-12">
          <h1 className="text-4xl font-bold">
            System Startup and Commissioning
          </h1>
          <p className="mt-4">
            Once the BAS has been installed and programmed, we verify the
            equipment works and runs to its design and full potential. We also
            check on secondary functions to ensure that the equipment runs
            optimally. System Commissioning is essential to keep these systems
            running in good condition and up to the client's requirements.
          </p>
        </div>
      </section>
    </div>
  );
}
