import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Header from "../../components/header";
import BGImage from '../../assets/images/pexels-linkedin-sales-navigator-2182973.jpg';

export default function Contact() {
  const form = useRef();
  const inputStyle =
    "py-2 px-4 border border-solid border-[#CCC] focus:outline-none focus:border-[#1F98B5] focus:ring-1 focus:ring-[#1F98B5] rounded-lg";
  const labelStyle = "after:content-['*'] after:ml-0.5 after:text-[#fa1919]";

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [submitted, setSubmitted] = useState();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true)

    const requestOptions = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "Origin": "appliedbas.com",
      },
      body: JSON.stringify({
        email: email,
        subject: subject,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        message: message,
      }),
    };

    try {
      const response = await fetch(
        "https://api.appliedbas.com/v1/mail/contact",
        requestOptions
      )
      if (response.status === 400) {
        setErrorMsg('Email address is invalid. Please change and try again.')
        setLoading(false);
        return;
      }
      
      if(response.status == 503) {
        setErrorMsg('Form submitted too many times. Please try again later.')
        setLoading(false);
        return;
      }

      setSubmitted(true);
    } catch (error) {
      setErrorMsg('Form could not be submitted at this time. Please try refreshing the page or try again later.')
    }
    finally {
      setLoading(false);
    }
  }

  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl lg:text-6xl font-bold text-white ">
        Contact Us
      </h1>
      <p className="drop-shadow-lg text-white text-xl first-letter:lg:text-2xl font-semibold mt-4 w-full">
        We will guide you through the maintenance and the important use of building automation.
      </p>
    </div>
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Contact | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content='These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Applied BAS ("Company", "we", "us" or "our"), concerning your access to and use of the www.appliedbasllc.com/terms-of-use website as well as any other media form, media channel, mobile website, or mobile application related, linked, or orhterwise connected thereto (collectively, the "Site"). We are registered in Texas, United States and have our registered office at 3605 Jasmine Ln, Rowlett, TX 75089. You agree that by accessing the Site, you have read, understood and agreed to the bound by all these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.'
        />
      </Helmet>
      <Header content={headerContent} image={BGImage} center />
      <section className="mt-4 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto grid grid-cols-1 lg:grid-cols-5">
          <div className="col-span-2">
            <div className="mb-8">
              <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
                <h1 className="text-3xl font-bold md:text-left">
                  Send us a Message
                </h1>

              </AnimationOnScroll>
              <p className="text-sm text-[#ff0000]">{errorMsg}</p>
            </div>
            {!submitted ? (
              <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
                <form
                  ref={form}
                  onSubmit={async (e) => await handleSubmit(e)}
                  className="w-full"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <span className={labelStyle}>First Name</span>
                      <input
                        type="text"
                        name="First Name"
                        placeholder="First Name"
                        className={inputStyle}
                        onChange={(val) =>
                          setFirstName(val.target.value.toString())
                        }
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className={labelStyle}>Last Name</span>
                      <input
                        type="text"
                        name="Last Name"
                        placeholder="Last Name"
                        className={inputStyle}
                        onChange={(val) =>
                          setLastName(val.target.value.toString())
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex flex-col">
                      <span className={labelStyle}>Email</span>
                      <input
                        type="text"
                        name="Email"
                        placeholder="Email"
                        className={inputStyle}
                        onChange={(val) => setEmail(val.target.value.toString())}
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <span>Phone</span>
                      <input
                        type="text"
                        name="Phone"
                        placeholder="Optional"
                        className={inputStyle}
                        onChange={(val) => setPhone(val.target.value.toString())}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col my-4">
                    <div className="flex flex-col">
                      <span className={labelStyle}>Subject</span>
                      <input
                        type="text"
                        name="Subject"
                        placeholder="Subject"
                        className={inputStyle}
                        onChange={(val) =>
                          setSubject(val.target.value.toString())
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col my-4">
                    <span className={`${labelStyle}`}>Message</span>
                    <textarea
                      name="Message"
                      placeholder="Message"
                      rows={4}
                      className={`${inputStyle} w-full`}
                      onChange={(val) => setMessage(val.target.value.toString())}
                      required
                    />
                  </div>
                  <div className="flex flex-row w-full">
                    <input
                      type="submit"
                      name="Submit"
                      value="Submit"
                      className={`${loading ? "bg-subtitle hover:bg-subtitle" : ""} content-center justify-center btn`}
                      disabled={loading}
                    />
                  </div>
                </form>
              </AnimationOnScroll>
            ) : (
              <p>
                The Form has been submitted successfully. Please allow for up to
                1 business day for us to respond to your inquiry.
              </p>
            )}
          </div>
          <div className="w-full flex flex-row lg:flex-col content-center items-center justify-center py-8 lg:py-0">
            <div className="w-1/3 h-0.5 lg:w-0.5 lg:h-1/3 bg-[#CCC]"></div>
            <div className="p-8">Or</div>
            <div className="w-1/3 h-0.5 lg:w-0.5 lg:h-1/3 bg-[#CCC]"></div>
          </div>
          <div className="w-full col-span-2 pt-4 pb-40 lg:py-0">
            <div className="">
              <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
                <h1 className="text-3xl font-bold md:text-left">
                  How Can We Help?
                </h1>
              </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
              <div className="mt-4">
                We would love to respond to any inquiries you may have as you seek
                the best automation systems for your building.
              </div>
            </AnimationOnScroll>
            <div className="my-4">
              <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
                <p className="">
                  <AiOutlinePhone className="inline-block mr-2" />
                  +1 (469) 676-8749
                </p>
              </AnimationOnScroll>
            </div>
            <div className="my-4">
              <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
                <a
                  className="hover:underline hover:underline-offset-4 hover:cursor-pointer"
                  href="mailto:info@appliedbas.com"
                >
                  <AiOutlineMail className="inline-block mr-2" />
                  info@appliedbas.com
                </a>
              </AnimationOnScroll>
            </div>
            <div className="my-4">
              <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
                <p className="">
                  <ImLocation className="inline-block mr-2" />
                  PO BOX 1991 Rowlett, TX 75030
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
