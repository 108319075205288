import { Helmet } from "react-helmet";
import CoorporateImg from "../../assets/images/1212 Corporate.png";
import MacArthurImg from "../../assets/images/6565-NMacArthur-Exterior-1-opt.jpg";
import NorthlakeImg from "../../assets/images/Northlake Church.png";
import Atriums from "../../assets/images/Atriums at Midway.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Header from "../../components/header";
import BGImage from '../../assets/images/pexels-pixabay-260931.jpg';

export default function Projects() {
  const Project = (props) => {
    return (
      <div className="flex flex-col gap-4">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
          <img className="h-[400px] object-cover rounded-lg w-full" src={props.img} alt={props.alt} />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
          <h3 className="text-xl font-bold">{props.title}</h3>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn" delay={200} animateOnce>
          <p className="">{props.description}</p>
        </AnimationOnScroll>
      </div>
    )
  }

  const headerContent = (
    <div className="flex flex-col h-full w-full justify-center">
      <h1 className="drop-shadow-lg text-4xl sm:text-6xl font-bold text-white ">
        Our Projects
      </h1>
      <p className="drop-shadow-lg text-white text-xl sm:text-2xl font-semibold mt-4 w-full">
        Where a select group of building automation ideas and plans were transformed into reality.
      </p>
    </div>
  );

  return (
    <div className="flex flex-col justify-center w-full">
      <Helmet>
        <title>Projects | Applied BAS</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
      </Helmet>
      <Header content={headerContent} image={BGImage} center />
      <section className="mt-20 mb-32 py-8 px-4 lg:px-8 w-full">
        <div className="w-full max-w-[1250px] m-auto">
          <h1 className="text-3xl font-bold">Featured Projects</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-8">
            <Project img={CoorporateImg} alt="1212 Corporate building" title="1212 Corporate Project" description="The Building Automation Retrofit implemented by Applied BAS consisted of a complete central plant controller changeout, including controlling the pneumatic axial vane AHU. Integrating the existing multivendor control system has proven to be the best solution for this 5-Story Class-A Sunwest Property located at 1212 Corporate in Irving, Texas." />
            <Project img={MacArthurImg} alt="6565 MacArthur building" title="6565 MacArthur Project" description="This Piedmont Property currently holds a LEED Gold certification and occupies a quarter of a million square feet of tenant space in Irving, Texas. Applied BAS provides Building Automation Services on the Niagara Honeywell BAS and has recently completed multiple projects working with Piedmont staff, upgrading legacy controllers to Bacnet and the BAS infrastructure." />
            <Project img={NorthlakeImg} alt="Northlake church" title="Northlake Church Project" description="Applied BAS retrofitted the old pneumatic controls for 3 Class B+ multistory buildings known as Midway Atriums in Dallas, Texas. The project consisted of 300+ FPB/VAVs controller replacements and interfacing with the factory-installed controls on the updated AHUs. As a result, the customer can now access three buildings from one access point to provide faster tenant requests." />
            <Project img={Atriums} alt="Midway Atriums" title="Midway Atriums Project" description="Working with H and G Systems in Dallas, Texas, Applied BAS provided Northlake Baptist Church with an utterly reliable HVAC control system. The project consisted of 40+ RTUs controlling multi-generations of building improvements. Now the staff can schedule the areas in the church as needed to serve the community better while saving energy." />
          </div>
        </div>
      </section>
    </div>
  );
}
